namespace $.$$ {
	
	$mol_style_define( $piterjs_meetup_templates, {
		
		flex: {
			basis: `30rem`,
		},
		
	} )
	
}
