namespace $.$$ {
	
	$mol_style_define( $piterjs_meetup_stats, {
		
		flex: {
			basis: `50rem`,
		},

		Chart: {
			flex: {
				basis: `20rem`,
			},
		},

	} )
	
}
