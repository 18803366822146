$mol_page $mol_view
	dom_name \article
	attr *
		^
		tabIndex <= tabindex -1
	sub /
		<= Head $mol_view
			minimal_height 64
			dom_name \header
			sub <= head /
				<= Title $mol_view
					dom_name \h1
					sub <= title_content /
						<= Logo null
						<= title
				<= Tools $mol_view
					sub <= tools /$mol_view_content
		<= Body $mol_scroll
			scroll_top? => body_scroll_top?
			sub <= body_content /
				<= Body_content $mol_view
					sub <= body /$mol_view
		<= Foot $mol_view
			dom_name \footer
			sub <= foot /$mol_view
