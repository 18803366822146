$piterjs_meetup_guests $mol_page
	theme \$mol_theme_special
	meetup $piterjs_meetup
		visitor*? => visitor*?
	title \Список гостей
	tools /
		<= Dump $mol_button_download
			file_name \guests.txt
			blob <= dump_blob $mol_blob
		<= Close $mol_link
			arg * guests null
			sub /
				<= Close_icon $mol_icon_close
	body /
		<= Content $mol_list rows /
			<= Filter $mol_search
				hint \Поиск...
				query? <=> filter? \
			<= Person_list $mol_list
				rows <= person_list /
					<= Person*0_0 $mol_view sub /
						<= Person_visitor* $mol_check_box
							checked? <=> visitor*?
							label /
								<= Person_snippet* $mol_dimmer
									haystack <= person* \
									needle <= filter
						<= Person_join_moment* $mol_view
							sub / <= person_join_moment* \
