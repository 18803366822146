namespace $.$$ {
	
	export class $mol_svg_image extends $.$mol_svg_image {
		
		pos_x() {
			return this.pos()[ 0 ]
		}
		
		pos_y() {
			return this.pos()[ 1 ]
		}
		
		size_x() {
			return this.size()[ 0 ]
		}
		
		size_y() {
			return this.size()[ 1 ]
		}
		
	}
	
}
