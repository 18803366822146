$piterjs_meetup_texts $mol_page
	theme \$mol_theme_special
	meetup $piterjs_meetup
		post_text* => post_text*
		post_moment*? => post_moment*?
	title \Тексты постов
	tools /
		<= Templates $mol_link
			arg * templates \
			sub /
				<= Templates_icon $mol_icon_pencil_outline
		<= Close $mol_link
			arg * texts null
			sub /
				<= Close_icon $mol_icon_close
	body /
		<= Content $mol_list rows /
			<= Init_text $piterjs_meetup_texts_card
				title \✨ Анонс митапа
				text <= post_text*init
				moment? <=> post_moment*init?
			^ speech_post_texts /
				<= Speech_post_text*0 $piterjs_meetup_texts_card
					title <= speech_post_title* \🗣️ {speaker}
					text <= speech_post_text* \
					moment? <=> speech_post_moment*? $mol_time_moment
			<= Place_text $piterjs_meetup_texts_card
				title \⌛ На кануне
				text <= post_text*place
				moment? <=> post_moment*place?
			<= Air_text $piterjs_meetup_texts_card
				title \⏰ Выход в эфир
				text <= post_text*air
				moment? <=> post_moment*air?
			<= Arterpaty_text $piterjs_meetup_texts_card
				title \🎊 Афтепати
				text <= post_text*afterparty
				moment? <=> post_moment*afterparty?
			<= Retro_text $piterjs_meetup_texts_card
				title \🙏 Ретроспектива
				text <= post_text*retro
				moment? <=> post_moment*retro?

$piterjs_meetup_texts_card $mol_expander
	Tools $mol_view sub /
		<= Date $mol_date value_moment? <=> moment? $mol_time_moment
		<= Copy $mol_button_copy
			text <= text \
	content / <= Text $mol_text
		text <= text \
