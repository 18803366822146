$piterjs_meetup_snippet $mol_link
	arg *
		meetup <= id \
		speech null
		now null
		video null
		place null
		others null
		wiki null
	meetup $piterjs_meetup
	sub /
		<= Title $mol_view
			sub / <= title \
		<= Date $mol_view
			sub / <= date \
