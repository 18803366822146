$mol_textarea $mol_stack
	attr *
		^
		mol_textarea_clickable <= clickable? false
		mol_textarea_sidebar_showed <= sidebar_showed false
	event *
		keydown?event <=> press?event null
		pointermove?event <=> hover?event null
	sub /
		<= Edit $mol_textarea_edit
			value? <=> value? \
			hint <= hint \ 
			enabled <= enabled true
			spellcheck <= spellcheck true
			length_max <= length_max +Infinity
			selection? <=> selection? /number
			bring => bring
			submit? <=> submit? null
			submit_with_ctrl <= submit_with_ctrl true
		<= View $mol_text_code
			text <= value
			render_visible_only false
			row_numb* <= row_numb* 0
			sidebar_showed <= sidebar_showed
			highlight <= highlight \
			syntax <= syntax $mol_syntax2
	symbols_alt *string
		comma \<
		period \>
		dash \−
		equals \≈
		graveAccent \́
		forwardSlash \÷
		E \€
		V \✔
		X \×
		C \©
		P \§
		H \₽
		key0 \°
		key8 \•
		key2 \@
		key3 \#
		key4 \$
		key6 \^
		key7 \&
		bracketOpen \[
		bracketClose \]
		slashBack \|
	symbols_alt_ctrl *string
		space \ 
	symbols_alt_shift *string
		V \✅
		X \❌
		O \⭕
		key1 \❗
		key4 \💲
		key7 \❓
		comma \«
		period \»
		semicolon \“
		quoteSingle \”
		dash \—
		equals \≠
		graveAccent \̱
		bracketOpen \{
		bracketClose \}

$mol_textarea_edit $mol_string
	dom_name \textarea
	enter \enter
	field *
		^
		scrollTop 0
