$piterjs_others_event $mol_link_iconed
	start $mol_time_moment
	target \_blank
	content /
		<= Location $mol_paragraph
			sub / <= location \
		<= Date $mol_paragraph
			sub / <= date \
		<= Title $mol_paragraph
			sub / <= title \
