$piterjs_schedule $mol_view
	meetup $piterjs_meetup
	sub /
		<= Speeches $mol_list
			rows <= speeches /
				<= Speech*0 $mol_list sub /
					<= Speech_main* $mol_view sub /
						<= Speech_start* $mol_view
							sub / <= speech_start* \19:20
						<= Speech_title* $mol_view
							attr * mol_theme \$mol_theme_accent
							sub / <= speech_title* \
					<= Speech_speaker* $mol_view
						sub / <= speech_speaker* \
