namespace $.$$ {

	export class $piterjs_others_event extends $.$piterjs_others_event {

		@ $mol_mem
		date() {
			return this.start().toString( "YYYY-MM-DD" )
		}

	}

}
