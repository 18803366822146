$piterjs_speech_snippet $mol_link
	arg *
		speech <= id \
		place null
		video null
	speech $piterjs_speech
	sub /
		<= Photo $piterjs_image link <= photo \
		<= Info $mol_list sub /
			<= Addon $mol_view sub /
				<= Speaker_title $mol_view
					sub / <= speaker_title \
				<= Time $mol_view sub / <= time \
			<= Title $mol_view
				sub / <= title \
