$piterjs_now $mol_page
	title \PiterJS
	meetups /
	attr *
		^
		tabindex null
	tools /
		<= Links $mol_view sub /
			<= Mail $mol_link
				uri \mailto:team@piterjs.org
				sub /
					<= Mail_icon $mol_icon_email
			<= Youtube $mol_link_iconed
				uri \https://www.youtube.com/@piterjs
				title \
			<= Vkontakte $mol_link_iconed
				uri \https://vk.com/piterjs
				title \
			<= Habr $mol_link_iconed
				uri \https://habr.com/ru/users/PiterJS/
				title \
			<= Telegram $mol_link_iconed
				uri \https://t.me/piterjs
				title \
			<= Github $mol_link_iconed
				uri \https://github.com/piterjs
				title \
	body /
		<= Screen $piterjs_screen
			place <= place $piterjs_place
			content /
				<= Logo $mol_svg_root
					view_box \0 0 270 270
					sub /
						<= Logo_angles $mol_svg_path
							geometry \M16.4 253.6h41v2.7l.1 13.7H0v-57.5h16.4v41zM256.3 57.6h-2.7V16.4h-41v-2.7L212.5 0H270v57.5l-13.7.1z
						<= Logo_image $mol_svg_path
							geometry \M218.3 164.7L212 162c-11-4.7-15.7-7.9-15.7-15.5 0-6.2 4.8-11 12.2-11 7.2 0 12 3.1 16.4 11l20-12.7c-8.5-14.6-20.1-20.3-36.4-20.3-23 0-37.5 14.6-37.5 33.6 0 20.7 12.2 30.5 30.8 38.4l6.4 2.7c11.8 5 18.7 8.2 18.7 16.9 0 7.3-6.8 12.5-17.4 12.5-12.7 0-19.7-6.5-25.2-15.5l-21 12c7.6 14.7 23 26 46.7 26 24.3 0 42.5-12.6 42.5-35.5 0-21.2-12.2-30.6-34-39.9zM123.7 201.3c0 12.7-5.3 16-13.7 16-8.8 0-12.4-6-16.4-13l-21 12.5c6 12.7 18 23.3 38.5 23.3 22.6 0 38.2-12 38.2-38.4v-86.9h-25.6v86.5zM111.4 65.4h-8l-.1-3.5h9c-3.4-4.7-2.2-13-.3-20h-9.2l1-3v-5.2H94l2 3-2 2.9h8l-.1 2.3h-9c-2 7-3.1 15.3.3 20h8.2l.1 3.1v.4H95.3c-.8 0-2 1.2-2.3 2.3H78.9v-3.5h13.7c-10.5-8.2 1.8-32.9 1.8-32.9h-16l1-7v-4.7h-9.9l2 2.4-2 2.3h7.4l-.1 7h-9.3s-12.3 24.7-1.8 33h10.6v3.4H53.6v-.3.1-5.6h7.3c-3.4-4.7-2.2-13-.3-20h-7.4v-2.3h.4v-6h-9.3l2 3-2 3h8v2.3h-8.4c-1.9 7-3 15.3.4 20h7.6l-.5 5.6v.2h-1.2V70h-.9L38.5 59v-8.8h-9.9l2 3-2 3h8.8v3l6.4 8.9s-.8 2.1-.4 3.5a50 50 0 0 0 6.6 7.9c1.4 1.5 3.6 6 3.6 6h24.8v6c-1.2.5-3.2 2-3.2 3.7 0 2.1 1.8 3.9 4.2 3.9 2.3 0 4.2-1.8 4.2-3.9 0-1.6-.5-3.2-2.8-3.6v-6.1H107l1.3-4.3c-5.2-2.3.3-7.8 3-10.2l1.6-5.5h-1.6v-.1zm-49 7.9h-2.3v-2.4h2.4v2.4zm4.7 0h-2.3v-2.4h2.3v2.4zm4.7 0h-2.3v-2.4h2.3v2.4zm4.7 0h-2.3v-2.4h2.3v2.4zm8.3 0h-2.4v-2.4h2.4v2.4zm4.7 0H87v-2.4h2.4v2.4zm4.6 0h-2.3v-2.4h2.3v2.4zm4.7 0h-2.3v-2.4h2.3v2.4z
				<= Descr $mol_paragraph
					title \Ежемесячные митапы в Питере о JavaScript, Node.js и всём, что с ними связано
				<= Bids $mol_row sub /
					<= Join $piterjs_link
						uri \#!wiki/=uxgccs_dqplnq
						title \Стать спикером
					<= Donate $piterjs_link
						uri \#!wiki/=wu07hq_io8xyr
						title \Поддержать нас
				<= Hint $mol_hint
					dictionary *
						menu \<= Куча инфы в базе знаний
