$piterjs_others $mol_page
	title \Календарь фронтенд событий
	tools /
		<= Add $mol_link
			uri \https://github.com/web-standards-ru/calendar
			target \_blank
			hint \Добавить ещё событие
			sub /
				<= Add_icon $mol_icon_plus
		<= Close $mol_link
			arg * others null
			sub /
				<= Close_icon $mol_icon_close
	body /
		<= Events $mol_list
			rows <= events /$mol_view
	Event!uid $piterjs_others_event
		title <= event_title!uid \
		uri <= event_uri!uid \
		start <= event_start!uid $mol_time_moment
		location <= event_location!uid \
