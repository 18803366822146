$piterjs_intro $mol_view
	title \PiterJS Вступление
	meetup $piterjs_meetup
	page?val \
	pages *$piterjs_intro_page
		main <= Main $piterjs_intro_main meetup <= meetup
		about <= About $piterjs_intro_page
			title \Кто мы?
			text \
				\Митапы в Питере
				\JS и всё, что рядом
				\Каждый месяц с 2015
				\Спячка с 2020
				\С 2023 снова с вами!
		projects <= Projects $piterjs_intro_page
			title \Наши проекты
			text \
				\PiterJS **Meetups** - митапы в Петербурге
				\PiterJS **Picnic** - прогулки и шашлыки
				\--
				\PiterJS **Conf** - конференции
				\PiterJS **Code+Learn** - воркшопы
				\PiterJS **Tour** - митапы в других городах
		comunity <= Community $piterjs_intro_page
			title \Сообщество
			text \
				\! YouTube
				\  ! 2100
				\! VKontakte
				\  ! 1900
				\! Telegram
				\  ! 1300
				\! Offline
				\  ! 70..100
		geo_quest <= Geo_quest $piterjs_intro_page
			title \Откуда мы?
			text \
				\Санкт-Петербург: 76%
				\Москва: 5%
				\???
				\???
				\???
				\Где-то на Земле: 17%
		geo_answer <= Geo_answer $piterjs_intro_page
			title \О такие мы!
			text \
				\Санкт-Петербург: 76%
				\Москва: 5%
				\**Рыбинск**: 0.75%
				\**Самара**: 0.64%
				\**Челябинск**: 0.61%
				\Где-то на Земле: 17%
		roles_org <= Roles_org $piterjs_intro_page
			title \Роли организаторов
			text \
				\Подготовка **докладчиков**
				\Коммуникация с **аудиторией**
				\Коммуникация с **партнёрами**
				\Фото и видео **монтаж**
				\**Дизайн** и эстетика бренда
				\**Сайт** и автоматизация 
				\**Координация** всех этих людей
		roles_place <= Roles_place $piterjs_intro_page
			title \Роли на площадке
			text \
				\**Встреча** гостей
				\**Ведение** мероприятия
				\**Видео** съёмка
				\**Фото** съёмка
				\Работа с **оборудованием**
				\Коммуникация с **аудиторией** online
				\**Координация** всех этих людей
		team <= Team $piterjs_intro_page
			title \Команда
			text \
				\! ""https://i.imgur.com/WYQiVs6.jpeg""
				\  ! ""https://i.imgur.com/dRolfnB.jpeg""
				\    ! ""https://i.imgur.com/n2Fxkqv.png""
				\      ! ""https://i.imgur.com/V2Rkj38.jpeg""
				\        ! ""https://habrastorage.org/webt/qb/wr/v4/qbwrv4ogxnynfpjfd17eivvcipu.jpeg""
				\! ""https://habrastorage.org/webt/xi/xd/hk/xixdhke23wtbhpnaz7gyuifpxnm.jpeg""
				\  ! ""https://i.imgur.com/wXGooly.jpeg""
				\    ! ""https://habrastorage.org/webt/mb/oz/2-/mboz2-qxenahgayijecyblbo6ka.jpeg""
				\      ! ""https://i.imgur.com/L9H07ur.jpeg""
				\        ! **Здесь можешь быть ты!**
		speakers <= Speakers $piterjs_intro_page
			title \Будь докладчиком
			text \
				\Нужны всегда
				\Даже если боишься
				\Поможем с темой
		schedule <= Schedule $piterjs_schedule
			meetup <= meetup
			title \Сегодня
		partnering <= Partnering $piterjs_intro_page
			title \Стань партнёром
			text \
				\**Подарки**: упоминание в соцсетях и на трансляции
				\**Кейтеринг**: + лого в трансляции и на видео
				\**Площадка**: + вступителное слово на мероприятии
		sponsors <= Sponsors $piterjs_intro_page
			title \Спонсоры
			text \
				\! ""https://habrastorage.org/webt/lj/hn/vy/ljhnvybz0o31ylka0kqpff8mnme.png""
				\! ""https://habrastorage.org/webt/w4/g6/6c/w4g66cuggs-skuvz2vnpspdtm_g.png""
				\! ""https://habrastorage.org/webt/b_/id/8y/b_id8y1art8zbhni8dgkzeegwfi.png""
				\  ! ""https://habrastorage.org/webt/xa/cr/so/xacrso7zaw-nhjcnnjedelzahpc.png""
		contribution <= Contribution $piterjs_intro_page
			title \Твой вклад
			text \
				\Поддерживай рублём
				\Привлекай партнёров
				\Зазывай докладчиков
				\Выступай сам
				\Вступай в наши ряды
				\Просто приходи
		profit <= Proft $piterjs_intro_page
			title \Бонусы
			text \
				\За лучшие вопросы - призы
				\Мы ведём трансляцию
				\И записываем видео
				\Улыбайтесь фотографу
		place <= Place $piterjs_intro_page
			title <= place_title \Мы в {place}
			text <= place_notes \
		- follow <= Follow $piterjs_intro_page
			title \Следите за новостями
			text \
				\vk.com/piterjs
				\t.me/piterjs
				\youtube.com/piterjs
				\piterjs.org
		afterparty <= Afterparty $piterjs_intro_page
			title \Го в бар!
			text <= afterparty \
	sub /
		<= Screen $piterjs_screen
			place <= place $piterjs_place
			content /
				<= Page $mol_view
	attr *
		tabindex -1
	plugins /
		<= Nav $mol_nav
			keys_x <= page_ids /string
			keys_y <= page_ids /string
			current_x?val <=> page?val
			current_y?val <=> page?val
